/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div style={{
        display: `relative`,
        paddingTop: 0,
      }}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            position: `absolute`,
            top: `0`,
            left: `330px`,
            width: `calc(100vw - 330px)`,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main style={{padding: `1.45rem 1.0875rem`}}>{children}</main>
          <footer>
            © {new Date().getFullYear()},
            {` `}
            <a href="https://twitter.com/cobafan">cobafan</a>
          </footer>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
